.title {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(40, end);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.main {
  padding-bottom: 140px;
  padding-top: 50px;
  position: relative;
  width: auto;
  height: 950px;
}

.main > .section > .content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/assets/resize-bg.webp");
  filter: blur(6px);
  z-index: -1;
}

.section {
  margin-bottom: 100px;
  justify-content: center;
  text-align: center;
  z-index: 1;
}

.logo-insani {
  margin-top: 230px;
  cursor: pointer;
}

.logo {
  width: 50px;
  height: 50px;
  text-align: center;
}

.container {
  max-height: 936px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.title {
  font-size: 46px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(154, 191, 240, 0.5);
}

.card-margin {
  margin: 25px;
  text-align: center;
  justify-content: center;
}

.menu {
  position: relative;
  width: 100%;
  height: 250px;
}

.menu-card {
  position: absolute;
  width: 135px;
  height: auto;
  top: 50%;
  left: 50%;
  transform-origin: center top;
}

p {
  font-weight: bold;
  color: #fff;
}

.logo-container {
  object-fit: cover;
  object-position: center;
  display: grid;
  justify-content: center;
}

.peduli-insani {
  height: 250px;
  width: 250px;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.info {
  margin-top: 8px;
}

.image-container {
  width: 750px;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.image-container-clicked {
  width: 185px;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.menu a {
  transition: transform 0.3a ease;
}
.menu a:hover {
  transform: scale(1.1);
}

.inline-inovy {
  width: auto;
  height: 120px;
  transition-delay: 1s;
}

.inovy {
  justify-content: center;
  text-align: center;
  height: auto;
  width: 100px;
  margin-top: 25px;
  margin: 10px;
  transform: rotate(0deg) translate(-10%, -350%);
}

.box-inovy {
  position: relative;
  border-radius: 20px 5px;
  overflow: hidden;
  height: 40px;
  width: 180px;
}

.box-inovy::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 40px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #1734c7, #39c829);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

.box-inovy::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 40px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #39c829, #1734c7);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
  animation-delay: -3s;
}

.hover-inovy {
  position: absolute;
  inset: 1px;
  border-radius: 20px 5px;
  z-index: 10;
  padding: 5px 5px;
  margin-top: 1px;
  margin-bottom: 2px;
  background-color: #d1d1d4;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-hi {
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: hi 2s forwards;
}

@keyframes hi {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.inovy img,
.inovy > p {
  animation: inovyAnimation 0s ease forwards;
  opacity: 0;
}

@keyframes inovyAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.card-img1 {
  transition: filter 0.3s ease, transform 0.3s ease;
  height: 85px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.card-img1:hover {
  filter: brightness(130%);
  transform: scale(1.2);
}

.inline-boomer {
  width: 115px;
  height: 120px;
}

.boomer {
  justify-content: center;
  text-align: center;
  height: auto;
  width: 100px;
  margin-top: 25px;
  margin: 10px;
  transform: rotate(0deg) translate(160%, -425%);
}

.box-boomer {
  position: relative;
  border-radius: 20px 5px;
  overflow: hidden;
  height: 65px;
  width: 270px;
}

.box-boomer::before {
  content: "";
  position: absolute;
  width: 250px;
  height: 65px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #1734c7, #39c829);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

.box-boomer::after {
  content: "";
  position: absolute;
  width: 250px;
  height: 65px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #39c829, #1734c7);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
  animation-delay: -3s;
}

.hover-boomer {
  position: absolute;
  inset: 1px;
  border-radius: 20px 5px;
  z-index: 10;
  padding: 5px 5px;
  margin-top: 1px;
  margin-bottom: 2px;
  background-color: #d1d1d4;
  overflow: hidden;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-hb {
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: hb 3s forwards;
}

@keyframes hb {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.boomer img,
.boomer > p {
  animation: boomerAnimation 1s ease forwards;
  opacity: 0;
}

@keyframes boomerAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.card-img2 {
  transition: filter 0.3s ease;

  height: 110px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.card-img2:hover {
  filter: brightness(130%);
  transform: scale(1.05);
}

.inline-toca {
  width: 100px;
  height: 120px;
}

.toca {
  justify-content: center;
  text-align: center;
  height: auto;
  width: 100px;
  margin-top: 25px;
  margin: 10px;
  transform: rotate(0deg) translate(230%, -380%);
}

.box-toca {
  position: relative;
  border-radius: 20px 5px;
  overflow: hidden;
  height: 45px;
  width: 230px;
}

.box-toca::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 45px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #1734c7, #39c829);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

.box-toca::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 45px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #39c829, #1734c7);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
  animation-delay: -3s;
}

.hover-toca {
  position: absolute;
  inset: 1px;
  border-radius: 20px 5px;
  z-index: 10;
  padding: 5px 5px;
  margin-top: 1px;
  margin-bottom: 2px;
  background-color: #d1d1d4;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-ht {
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: ht 3s forwards;
}

@keyframes ht {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.toca img,
.toca > p {
  animation: tocaAnimation 1.5s ease forwards;
  opacity: 0;
}

@keyframes tocaAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.p-toca {
  margin-top: 15px;
}

.card-img3 {
  transition: filter 0.3s ease;

  height: 90px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.card-img3:hover {
  filter: brightness(130%);
  transform: scale(1.05);
}

.inline-wbs {
  width: 140px;
  height: 120px;
}

.wbs {
  justify-content: center;
  text-align: center;
  height: auto;
  width: 100px;
  margin-top: 25px;
  margin: 10px;
  transform: rotate(0deg) translate(160%, -355%);
}

.box-wbs {
  position: relative;
  border-radius: 20px 5px;
  overflow: hidden;
  height: 45px;
  width: 280px;
}

.box-wbs::before {
  content: "";
  position: absolute;
  width: 250px;
  height: 45px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #1734c7, #39c829);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

.box-wbs::after {
  content: "";
  position: absolute;
  width: 250px;
  height: 45px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #39c829, #1734c7);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
  animation-delay: -3s;
}

.hover-wbs {
  position: absolute;
  inset: 1px;
  border-radius: 20px 5px;
  z-index: 10;
  padding: 5px 5px;
  margin-top: 1px;
  margin-bottom: 2px;
  background-color: #d1d1d4;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-hw {
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: hw 3s forwards;
}

@keyframes hw {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.wbs img,
.wbs > p {
  animation: wbsAnimation 2s ease forwards;
  opacity: 0;
}

@keyframes wbsAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.p-wbs {
  margin-bottom: 40px;
}

.card-img4 {
  transition: filter 0.3s ease;
  width: 140px;
  height: 110px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.card-img4:hover {
  filter: brightness(130%);
  transform: scale(1.05);
}

.inline-kopkar {
  width: 115px;
  height: 120px;
}

.kopkar {
  justify-content: center;
  text-align: center;
  height: auto;
  width: 100px;
  margin-top: 25px;
  margin: 10px;
  transform: rotate(0deg) translate(0%, -350%);
}

.box-kopkar {
  position: relative;
  border-radius: 20px 5px;
  overflow: hidden;
  height: 65px;
  width: 210px;
}

.box-kopkar::before {
  content: "";
  position: absolute;
  width: 180px;
  height: 65px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #1734c7, #39c829);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

.box-kopkar::after {
  content: "";
  position: absolute;
  width: 180px;
  height: 65px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #39c829, #1734c7);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
  animation-delay: -3s;
}

.hover-kopkar {
  position: absolute;
  inset: 1px;
  border-radius: 20px 5px;
  z-index: 10;
  padding: 5px 5px;
  margin-top: 1px;
  margin-bottom: 2px;
  background-color: #d1d1d4;
  overflow: hidden;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-hk {
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: hk 3s forwards;
}

@keyframes hk {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.kopkar img,
.kopkar > p {
  animation: kopkarAnimation 2.5s ease forwards;
  opacity: 0;
}

@keyframes kopkarAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.card-img5 {
  transition: filter 0.3s ease;

  height: 85px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.card-img5:hover {
  filter: brightness(130%);
  transform: scale(1.05);
}

.inline-it {
  width: 130px;
  height: 120px;
}

.it {
  justify-content: center;
  text-align: center;
  height: auto;
  width: 100px;
  margin-top: 25px;
  margin: 10px;
  transform: rotate(0deg) translate(-180%, -510%);
}

.box-it {
  position: relative;
  border-radius: 20px 5px;
  overflow: hidden;
  height: 45px;
  width: 240px;
}

.box-it::before {
  content: "";
  position: absolute;
  width: 210px;
  height: 45px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #1734c7, #39c829);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

.box-it::after {
  content: "";
  position: absolute;
  width: 210px;
  height: 45px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #39c829, #1734c7);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
  animation-delay: -3s;
}

.hover-it {
  position: absolute;
  inset: 1px;
  border-radius: 20px 5px;
  z-index: 10;
  padding: 5px 5px;
  margin-top: 1px;
  margin-bottom: 2px;
  background-color: #d1d1d4;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-hit {
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: hit 3s forwards;
}

@keyframes hit {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.it img,
.it > p {
  animation: itAnimation 3s ease forwards;
  opacity: 0;
}

@keyframes itAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.p-it {
  margin-top: 23px;
}

.card-img6 {
  transition: filter 0.3s ease;
  margin-top: 10px;

  height: 55px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.card-img6:hover {
  filter: brightness(130%);
  transform: scale(1.05);
}

.inline-cla {
  width: 110px;
  height: 100px;
}

.cla {
  justify-content: center;
  text-align: center;
  height: auto;
  width: 100px;
  margin-top: 25px;
  margin: 10px;

  transform: rotate(0deg) translate(-235%, -995%);
}

.box-cla {
  position: relative;
  border-radius: 20px 5px;
  overflow: hidden;
  height: 65px;
  width: 180px;
}

.box-cla::before {
  content: "";
  position: absolute;
  width: 150px;
  height: 65px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #1734c7, #39c829);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

.box-cla::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 65px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #39c829, #1734c7);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
  animation-delay: -3s;
}

.hover-cla {
  position: absolute;
  inset: 1px;
  border-radius: 20px 5px;
  z-index: 10;
  padding: 5px 5px;
  margin-top: 1px;
  margin-bottom: 2px;
  background-color: #d1d1d4;
  overflow: hidden;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-hc {
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: hc 3s forwards;
}

@keyframes hc {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.cla img,
.cla > p {
  animation: claAnimation 3.5s ease forwards;
  opacity: 0;
}

@keyframes claAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.card-img7 {
  transition: filter 0.3s ease;

  height: 115px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.card-img7:hover {
  filter: brightness(130%);
  transform: scale(1.05);
}

.inline-smartco {
  width: 100px;
  height: 120px;
}

.smartco {
  justify-content: center;
  text-align: center;
  height: auto;
  width: 100px;
  margin-top: 25px;
  margin: 10px;
  transform: rotate(0deg) translate(-165%, -1055%);
}

.box-smartco {
  position: relative;
  border-radius: 20px 5px;
  overflow: hidden;
  height: 45px;
  width: 220px;
}

.box-smartco::before {
  content: "";
  position: absolute;
  width: 190px;
  height: 45px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #1734c7, #39c829);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
}

.box-smartco::after {
  content: "";
  position: absolute;
  width: 190px;
  height: 45px;
  top: -50%;
  left: -50%;
  background: linear-gradient(50deg, transparent, #39c829, #1734c7);
  transform-origin: bottom right;
  animation: animate 4s linear infinite;
  animation-delay: -3s;
}

.hover-smartco {
  position: absolute;
  inset: 1px;
  border-radius: 20px 5px;
  z-index: 10;
  padding: 5px 5px;
  margin-top: 1px;
  margin-bottom: 2px;
  background-color: #d1d1d4;
  overflow: hidden;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.p-hs {
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  animation: hs 3s forwards;
}

@keyframes hs {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.smartco img,
.smartco > p {
  animation: smartcoAnimation 4s ease forwards;
  opacity: 0;
}

@keyframes smartcoAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.p-smartco {
  margin-top: 16px;
  margin-right: 10px;
}

.card-img8 {
  transition: filter 0.3s ease;

  height: 80px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.card-img8:hover {
  filter: brightness(130%);
  transform: scale(1.05);
}

.footer {
  background-color: #0f4c75;
  color: #fff;
  padding: 15px;
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-link {
  color: #fff;
  margin: 5px 35px 5px 5px;
  text-decoration: none;
}

.teks {
  font-size: 16px;
}

@media (max-width: 768px) {
  .main {
    height: 650px;
  }

  .main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 768;
    height: 650px;
    background-size: cover;
    background-image: url("../../public/assets/resize-bg.webp");
    filter: blur(6px);
    z-index: -1;
  }

  .container {
    max-height: 650px;
    overflow-y: hidden;
    overflow-x: hidden;
  }

 

 .box-inovy , .box-boomer, .box-toca, .box-wbs, .box-kopkar, .box-it, .box-cla, .box-smartco {
  display: none;
 }

  .footer {
    background-color: #0f4c75;
    color: #fff;
    padding: 5px;
    text-align: center;
  }

  .footer .container {
    margin: auto;
    font-size: 10px;
  }

  .footer .teks {
    text-align: center;
    font-size: 12px;
  }

  .footer .text-link {
    color: #fff;
    margin: 5px 10px 5px 5px;
    text-decoration: none;
  }

  .social-icons {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .logo-insani {
    margin-top: 120px;
  }

  .title {
    font-size: 19px;
  }

  .text-link {
    font-size: 10px;
  }

  .teks {
    font-size: 15px;
  }

  p {
    font-size: 14px;
  }

  .peduli-insani {
    width: 150px;
    height: 150px;
  }

  .container {
    margin-top: 0px;
  }

  .inline-inovy {
    width: 85px;
    height: 120px;
  }

  .inovy {
    transform: rotate(0deg) translate(15%, -215%);
  }

  

  .card-img1 {
    height: 55px;
  }

  .inline-boomer {
    width: 65px;
    height: 120px;
  }

  .boomer {
    transform: rotate(0deg) translate(130%, -305%);
  }

  .card-img2 {
    height: 60px;
  }

  .inline-toca {
    width: 55px;
    height: 120px;
  }

  .toca {
    transform: rotate(0deg) translate(165%, -320%);
  }

  .card-img3 {
    height: 55px;
  }

  .inline-wbs {
    width: 85px;
    height: 120px;
  }

  .wbs {
    transform: rotate(0deg) translate(105%, -345%);
  }

  .card-img4 {
    width: 110px;
    height: 85px;
  }

  .inline-kopkar {
    width: 85px;
    height: 120px;
  }

  .kopkar {
    transform: rotate(0deg) translate(10%, -420%);
  }

  .card-img5 {
    height: 55px;
  }

  .inline-it {
    width: 75px;
    height: 120px;
  }

  .it {
    transform: rotate(0deg) translate(-90%, -511%);
  }

  .p-it {
    margin-top: 15px;
  }

  .card-img6 {
    height: 40px;
  }

  .inline-cla {
    width: 85px;
    height: 120px;
  }

  .cla {
    transform: rotate(0deg) translate(-135%, -769%);
  }

  .p-cla {
    margin-top: 3px;
  }

  .card-img7 {
    height: 75px;
  }

  .inline-smartco {
    width: 55px;
    height: 120px;
  }

  .smartco {
    transform: rotate(0deg) translate(-80%, -963%);
  }

  .p-smartco {
    margin-top: 5px;
    margin-right: 0px;
  }

  .card-img8 {
    height: 55px;
  }
}
